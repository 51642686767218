import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductCardContainer as SourceProductCardContainer,
} from 'SourceComponent/ProductCard/ProductCard.container';
import { getSmallImage } from 'Util/Product/Extract';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace Theme/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    static defaultProps = {
        ...super.defaultProps,
        imageLoad: 'lazy',
    };

    containerProps() {
        const {
            children,
            mix,
            layout,
            hideCompareButton,
            hideWishlistButton,
            isLoading,
            renderContent,
            product,
            isPlp,
            onLoad,
            isMobile,
            imageLoad,
        } = this.props;

        return {
            ...super.containerProps(),
            children,
            hideCompareButton,
            hideWishlistButton,
            isLoading,
            layout,
            mix,
            renderContent,
            isPlp,
            thumbnail: getSmallImage(this.getActiveProduct()) || getSmallImage(product),
            linkTo: this.getLinkTo(),
            onLoad,
            isMobile,
            imageLoad,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
