import {
    ImageContainer as SourceImageContainer,
} from 'SourceComponent/Image/Image.container';
import { noopFn } from 'Util/Common';

import {
    ImageRatio,
} from './Image.type';
/** @namespace Theme/Component/Image/Container */
export class ImageContainer extends SourceImageContainer {
    static defaultProps = {
        src: '',
        alt: '',
        ratio: ImageRatio.IMG_SQUARE,
        mix: {},
        height: '',
        width: '',
        isPlaceholder: false,
        style: {},
        title: undefined,
        className: '',
        imageRef: undefined,
        isPlain: false,
        showIsLoading: false,
        onImageLoad: noopFn,
        isRenderWhenVisible: false,
        loading: 'lazy',
    };

    containerProps() {
        const {
            isPlaceholder,
            src,
            title,
            alt,
            ratio,
            mix,
            imageRef,
            isPlain,
            showIsLoading,
            onImageLoad,
            isRenderWhenVisible,
            loading,
        } = this.props;

        return {
            style: this._getStyle(),
            wrapperSize: this._getWrapperSize(),
            isPlaceholder,
            src,
            title,
            alt,
            className: this._getCorrectClassNames(),
            ratio,
            mix,
            imageRef,
            isPlain,
            showIsLoading,
            isCached: this._isCached(),
            onImageLoad,
            isRenderWhenVisible,
            loading,
        };
    }
}

export default ImageContainer;
